<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.type"
              placeholder="分类"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.categoryType" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.keyword"
              placeholder="分类名称/描述"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button v-hasPermission="'cate:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">
          新增
        </a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="record.logo" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.logo || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.logo || '').split(',')"
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-switch :checked="record.status==1" @change="changeStatus(record,'status')" :loading1="showLoading"/>
        <!--        <a-tag :color="record.status | dictName('validStatus', 'color')">{{-->
        <!--            record.status | dictName("validStatus")-->
        <!--          }}-->
        <!--        </a-tag>-->
      </template>
      <template slot="type" slot-scope="text, record">
        <a-tag :color="record.type | dictName('categoryType', 'color')">{{
            record.type | dictName("categoryType")
          }}
        </a-tag>
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-tag :color="record.isTop | dictName('yesNo', 'color')">{{
            record.isTop | dictName("yesNo")
          }}
        </a-tag>
      </template>
      <template slot="indexFlag" slot-scope="text, record">
        <a-tag :color="text| dictName('yesNo', 'color')">{{
            text | dictName("yesNo")
          }}
        </a-tag>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" icon="edit" size="small" @click="modify(record)">
          修改
        </a-button>
        <a-button type="danger" icon="delete" size="small" style="margin-left:10px" @click="getDelete(record)">
          删除
        </a-button>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyCategoryModal";

export default {
  name: "CircleCategoryList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "category/cateList",
      listMethod: "postJson",
      showLoading: false
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "cateId",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'cateId' && sortedInfo.order
        },
        {
          title: "Logo",
          width: 100,
          align: "center",
          dataIndex: "logo",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "类型",
          align: "center",
          width: 100,
          dataIndex: "type",
          scopedSlots: {customRender: "type"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order
        },
        {
          title: "分类名称",
          align: "center",
          width: 150,
          dataIndex: "cateName",
        },

        {
          title: "状态",
          align: "center",
          width: 100,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
        },
        {
          title: "是否置顶",
          align: "center",
          width: 100,
          dataIndex: "isTop",
          scopedSlots: {customRender: "isTop"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'isTop' && sortedInfo.order
        },
        // {
        //   title: "首页展示",
        //   align: "center",
        //   width: 150,
        //   dataIndex: "indexFlag",
        //   scopedSlots: {customRender: "indexFlag"},
        // },
        // {
        //   title: "是否自救手册分类",
        //   align: "center",
        //   width: 150,
        //   dataIndex: "manualFlag",
        //   scopedSlots: {customRender: "indexFlag"},
        // },
        {
          title: "排序号",
          align: "center",
          width: 100,
          dataIndex: "sortNumber",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'sortNumber' && sortedInfo.order
        },
        {
          title: "说明",
          align: "center",
          width: 250,
          ellipsis: true,
          dataIndex: "remark"
        },
        {
          title: "操作",
          width: 200,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'edit');
    },
    add() {
      this.$refs.modifyModal.showModal(null, 'new');
    },
    getDelete(record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.cateName + "】分类吗？！",
        centered: true,
        onOk() {
          that.$postJson("category/delete", [record.cateId]).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
    changeStatus(record) {
      let that = this;
      let targetStatus = record.status == 1 ? 0 : 1;

      let data = {
        cateId: record.cateId,
        status: targetStatus
      };

      that.showLoading = true;
      that.$post("category/updateStatus", data).then(() => {
        that.showLoading = false;
        let mes = `ID：【${record.cateName}】设置状态成功`;
        record.status = targetStatus;
        that.$message.success(mes);
        this.search();
      }).catch(() => {
        that.showLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
